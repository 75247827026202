<template>
  <div class="experience">
    <div class="container">
      <Company logo="ozon.png" name="Ozon Technologies (ozon.ru)" position=".NET Software Developer"
               date="May 2021 - Present" link="https://www.ozon.ru/"
               description="Development of WMS microservice system functionality (backend) in TMC group (defect and surplus on stock, also message bus between WMS and accountant system), writing autotests (integration, unit, E2E testing)."
               technologies="C#, .Net 6, PostgreSql, Dapper, Docker, k8s, Kafka, Gitlab, Jaeger, GreyLog, Grafana, Prometheus, Kanban"/>
      <Company logo="mts.png" name="MTS Digital (mts.ru)" position=".NET Software Developer"
               date="Dec 2018 - May 2021" link="https://mts.ru/"
               description="Development of CRM system (monolith) functionality (backend/frontend) in 'Figa/Sales' group (sales of simcards and contracts, external interaction layer with different foreign systems), writing autotests (E2E testing)."
               technologies="C#, JS, TS, .Net Framework 4.7.2/Core 2.0, PostgreSql, Oracle, ASF, Azure DevOps (TFS)"/>
      <Company logo="codeclass.webp" name="CodeClass (code-class.ru)" position="Programming Teacher"
               date="Oct 2017 - Jun 2018" link="https://code-class.ru/"
               description="Teaching game development for kids (12-16 y.o.), Unity with C# coding and Unreal Engine 4 with blueprints. Training for my presentation skills."
               technologies="C#, Unity 5, Unreal Engine 4, PowerPoint :D"/>
    </div>
  </div>
</template>

<script>
import Company from "./Company";

export default {
  name: "experience-item",
  components: {
    Company
  }
}
</script>

<style scoped>
.experience {
  color: #808080;
}
a {
  color: #808080;
}
a:hover {
  color: #555555;
  text-decoration: none;
}
h5 {
  font-size: 14pt;
  color: #752E9D;
}
</style>