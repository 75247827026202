<template>
  <div data-aos="fade-up" class="row">
    <div class="d-none d-sm-block col-sm-4">
      <img class="company-image mx-auto d-block float-end" :src="require('../assets/' + logo)" alt="Company logo">
    </div>
    <div class="lol col-12 col-sm-7">
      <a :href="link">
        <h4 class="font-weight-bold text-left">
          <img class="company-image-sm mx-auto d-block d-sm-none float-start" :src="require('../assets/' + logo)"
               alt="Company logo">
          {{ name }}
        </h4>
      </a>
      <div class="position-lb font-weight-bold">{{ position }}</div>
      <h5 class="font-weight-bold text-left">{{ date }}</h5>
      <div class="description">
        {{ description }}
        <br>
        <div class="technologies">
          {{ technologies }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faAngleRight)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
  name: "company-item",
  props: {
    logo: String,
    name: String,
    position: String,
    date: String,
    link: String,
    description: String,
    technologies: String
  }
}
</script>

<style scoped>
a {
  color: #808080;
}

a:hover {
  color: #555555;
  text-decoration: none;
}

.position-lb {
  font-weight: bold;
  color: #4551b8;
}

.company-image {
  background-size: cover;
  margin-top: 10px;
  height: auto;
  width: 120px;
}

.company-image-sm {
  background-size: cover;
  padding: 2px;
  height: auto;
  width: 50px;
}

.row {
  padding-bottom: 40px;
}

h5 {
  font-size: 14pt;
  color: #752E9D;
}

.technologies {
  font-size: 12pt;
  font-weight: lighter;
}
</style>