<template>
  <div class="about-me">
    <div class="container">
      <div class="row">
        <div data-aos="fade-up" class="col-12 col-lg-7 pt-lg-0">
          <h4 class="font-weight-bolder text-center">Education</h4>
          <div class="container">
            <University name="МИРЭА (RTU MIREA)" name_lower="Российский технологический университет"
                        education_level="Bachelor of Computer Science" years="2016 - 2020"
                        department="Department of Industrial informatics"
                        graduation="09.03.01 - Informatics and computer engineering" image="mirea.png"
                        href="https://www.mirea.ru/"
            />
            <div data-aos="fade-up">
              <Certificates/>
            </div>
          </div>
        </div>
        <div class="vl col-lg-1 d-none d-lg-block"/>
        <Stack/>
      </div>
    </div>
  </div>
</template>

<script>
import University from "./University";
import Stack from "./Stack.vue";
import Certificates from "./Certificates";

export default {
  name: "about-me-item",
  components: {
    University,
    Stack,
    Certificates
  }
}
</script>

<style scoped>
.about-me {
  color: #808080;
}

.about-me a {
  color: #808080;
}

.about-me a:hover {
  color: #555555;
  text-decoration: none;
}

.about-me h4 {
  margin: 1em;
}

.about-me h5 {
  font-size: 14pt;
  color: #752E9D;
}

.vl {
  border-left: 1px solid #d7d7d7;
  height: 35em;
  margin-top: 1em;
}
</style>