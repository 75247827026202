<template>
  <div class="info">
    <div data-aos="fade-up">
      <div class="container">
        <div class="row col-12 m-0 mt-5 justify-content-center">
          <h2 class="font-weight-bold">Vasilenko Vladislav</h2>
        </div>
        <div class="row col-12 m-0 justify-content-center">
          <h5 class="font-weight-normal">Software .NET Developer</h5>
        </div>
        <div class="row col-12 m-0">
          <img class="my-image rounded-circle mx-auto d-block" alt="My photo" src="../assets/my_photo.jpg">
        </div>
        <div class="row col-12 m-0 justify-content-center">
          <h5 class="font-weight-normal">Programmatic creation of our future</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "info-item"
}
</script>

<style scoped>
.my-image {
  height: 300px;
  width: 320px;
}

.info {
  color: #999999;
}

.info .container {
  margin-top: 115vh;
  margin-bottom: 10vh;
}

.info h2 {
  margin-top: 5%;
  color: #999999;
}

.info img,
.info h5 {
  margin-bottom: 3%;
}
</style>