<template>
  <div class="row">
    <div class="col-7">
      {{ obj }}
    </div>
    <div class="col-5 text-right">
      <font-awesome-icon v-if="count > 0" icon="circle" class="icon icon-1"/>
      <font-awesome-icon v-if="count < 1" :icon="['far', 'circle']" class="icon"/>
      <font-awesome-icon v-if="count > 1" icon="circle" class="icon icon-2"/>
      <font-awesome-icon v-if="count < 2" :icon="['far', 'circle']" class="icon"/>
      <font-awesome-icon v-if="count > 2" icon="circle" class="icon icon-3"/>
      <font-awesome-icon v-if="count < 3" :icon="['far', 'circle']" class="icon"/>
      <font-awesome-icon v-if="count > 3" icon="circle" class="icon icon-4"/>
      <font-awesome-icon v-if="count < 4" :icon="['far', 'circle']" class="icon"/>
      <font-awesome-icon v-if="count > 4" icon="circle" class="icon icon-5"/>
      <font-awesome-icon v-if="count < 5" :icon="['far', 'circle']" class="icon"/>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCircle, farCircle)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
  name: "circles-item",
  props: {
    obj: String,
    count: Number
  }
}
</script>

<style scoped>
.icon {
  padding: 1px;
  font-size: medium;
}
.icon-1 {
  color: #808080;
}
.icon-2 {
  color: #737373;
}
.icon-3 {
  color: #656565;
}
.icon-4 {
  color: #5e5e5e;
}
.icon-5 {
  color: #525252;
}
</style>