<template>
  <div data-aos="fade-up" class="col-12 pt-5 pt-lg-0 col-lg-4">
    <h4 class="font-weight-bolder text-center">Stack</h4>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5><b>Languages</b></h5>
        </div>
      </div>
      <Circles obj="C#" :count="5"/>
      <Circles obj="JS" :count="3"/>
      <Circles obj="Python" :count="2"/>
    </div>
    <br>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5><b>Frameworks</b></h5>
        </div>
      </div>
      <Circles obj="ASP.NET Core/Framework" :count="4"/>
      <Circles obj="VueJs" :count="2"/>
      <Circles obj="React" :count="1"/>
    </div>
    <br>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5><b>Protocols and Technologies</b></h5>
        </div>
      </div>
      <Circles obj="SOAP" :count="4"/>
      <Circles obj="REST" :count="5"/>
      <Circles obj="WCF" :count="4"/>
      <Circles obj="Docker/Kubernetes/ASF" :count="2"/>
    </div>
    <br>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5><b>Databases</b></h5>
        </div>
      </div>
      <Circles obj="Oracle" :count="2"/>
      <Circles obj="PostgreSql" :count="3"/>
      <Circles obj="MongoDB" :count="1"/>
    </div>
  </div>
</template>

<script>
import Circles from "./Circles";

export default {
  name: "stack-item",
  components: {
    Circles
  }
}
</script>

<style scoped>
.about-me h4 {
  margin: 1em;
}
.about-me h5 {
  font-size: 14pt;
  color: #752E9D;
}
.about-me a {
  color: #808080;
}
.about-me a:hover {
  color: #555555;
  text-decoration: none;
}
</style>