<template>
  <div class="body">
    <div class="bg-image">
      <div data-aos="fade-up">
        <div class="container">
          <div class="row col-12 m-0 justify-content-center">
            <h2 class="font-weight-bold">Vasilenko Vladislav</h2>
          </div>
          <div class="row col-12 m-0 justify-content-center">
            <h5 class="font-weight-normal">bio</h5>
          </div>
        </div>
      </div>
    </div>
    <Info />
    <hr id="about-me"/>
    <AboutMe />
    <hr id="experience"/>
    <Experience />
    <Contact />
  </div>
</template>

<script>
import Info from './Info'
import AboutMe from "./AboutMe";
import Experience from "./Experience";
import Contact from "./Contact"

export default {
  name: "body-item",
  components: {
    Info,
    AboutMe,
    Experience,
    Contact
  }
}
</script>

<style scoped>
.body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14pt;
  color: white;
}
.bg-image {
  background: url(../assets/bg_forest.jpg) no-repeat center center fixed;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;
}
.bg-image .container {
  margin-top: 28vh;
  margin-bottom: 65vh;
}

hr {
  margin: 15vh 15vw;
}
</style>