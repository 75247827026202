<template>
  <div class="header fixed-top">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 pt-4 pb-2">
          <div class="container">
            <div class="row justify-content-center">
              <div data-aos="fade-down" class="button col-4 col-sm-3 text-end">
                <a href="#about-me">About me</a>
              </div>
              <div data-aos="fade-down" class="button col-4 col-sm-3">
                <a href="#experience">Experience</a>
              </div>
              <div data-aos="fade-down" class="button col-4 col-sm-3 text-start">
                <a href="#contact">Contact</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-item"
}
</script>

<style scoped>
.header {
  border-bottom: 1px solid #787878;
  background-color: #ECECEC;
}
.header a {
  color: #808080;
  text-decoration: none;
}
.header a:hover {
  color: #555555;
  text-decoration: none;
}
.button {
  padding: 0.1em 0.2em 0.1em 0.2em;
}
</style>