<template>
  <div class="row">
    <carousel :autoplay="true" :perPageCustom="[[480, 2], [768, 3]]">
      <slide>
        <Certificate href="https://www.coursera.org/account/accomplishments/specialization/certificate/A8RJUHK9AQRE" image="postgresql_0.jpeg" />
      </slide>
      <slide>
        <Certificate href="https://www.coursera.org/account/accomplishments/certificate/9GTWY5RP4KES" image="postgresql_1.jpeg" />
      </slide>
      <slide>
        <Certificate href="https://www.coursera.org/account/accomplishments/certificate/LEBYDTP5ER2R" image="postgresql_2.jpeg" />
      </slide>
      <slide>
        <Certificate href="https://www.coursera.org/account/accomplishments/certificate/SKV472HE8WZV" image="postgresql_3.jpeg" />
      </slide>
      <slide>
        <Certificate href="https://www.coursera.org/account/accomplishments/certificate/MASHCTY5BPGA" image="postgresql_4.jpeg" />
      </slide>
    </carousel>
  </div>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';
import Certificate from "./Certificate";

export default {
  name: "certificates-item",
  components: {
    Carousel,
    Slide,
    Certificate
  }
}
</script>

<style scoped>
.row {
  margin: 6em 0 0 0;
}
</style>