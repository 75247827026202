<template>
  <div>
    <div class="footer d-block d-sm-none">
      <div class="container">
        <div class="row justify-content-center">
          <div class="d-none d-sm-block col-12 text-center pt-2">
            Social
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 text-center" style="padding-top: 3px;">
            <a href="https://www.linkedin.com/in/sgsdeveloper/">
              <font-awesome-icon :icon="['fab', 'linkedin']"/>
            </a>
            <a href="https://github.com/BoredBear">
              <font-awesome-icon :icon="['fab', 'github']"/>
            </a>
            <a href="https://vk.com/vvvdeveloper">
              <font-awesome-icon :icon="['fab', 'vk']"/>
            </a>
            <a href="mailto:vasilenko-vlad@mail.ru?subject=Bored%20Invitation">
              <font-awesome-icon icon="envelope"/>
            </a>
          </div>
        </div>
        <div class="row mt-0 mt-sm-3 justify-content-center">
          <div class="col-12 d-block d-sm-none text-center">
            <p class="mobile">Vasilenko Vladislav</p>
            <p class="mobile">2022</p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer d-none d-sm-block" style="height: 140px;">
      <div class="container" style="height: 140px;">
        <div class="row justify-content-center">
          <div class="d-none d-sm-block col-12 text-center pt-2">
            Social
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 text-center" style="padding-top: 3px;">
            <a href="https://www.linkedin.com/in/sgsdeveloper/">
              <font-awesome-icon :icon="['fab', 'linkedin']"/>
            </a>
            <a href="https://github.com/BoredBear">
              <font-awesome-icon :icon="['fab', 'github']"/>
            </a>
            <a href="https://vk.com/vvvdeveloper">
              <font-awesome-icon :icon="['fab', 'vk']"/>
            </a>
            <a href="mailto:vasilenko-vlad@mail.ru?subject=Bored%20Invitation">
              <font-awesome-icon icon="envelope"/>
            </a>
          </div>
        </div>
        <div class="row mt-0 mt-sm-3 justify-content-center">
          <div class="col-12 d-none d-sm-block text-center">
            <p>Vasilenko Vladislav</p>
            <p>2022</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faVk, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faEnvelope, faVk, faLinkedin, faGithub)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
  name: "footer-item"
}
</script>

<style scoped>
.footer {
  height: 4em;
  border-top: 1px solid #787878;
  background-color: #ECECEC;
}

.footer a {
  color: #808080;
}

.footer i {
  font-size: 18pt;
}

.footer p {
  font-size: 12pt;
  margin: 0;
  padding: 0;
  color: #979696;
}

.footer .mobile {
  font-size: 9pt;
}

.footer a:hover {
  color: #555555;
  text-decoration: none;
}

a {
  font-size: large;
  margin: 1px;
}
</style>