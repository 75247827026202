<template>
  <a :href="href">
    <img :src="require('../assets/' + image)" alt="Certificate image">
  </a>
</template>

<script>
export default {
  name: "certificate-item",
  props: {
    href: String,
    image: String
  }
}
</script>

<style scoped>
img {
  width: 200px;
  height: 150px;
}
</style>