<template>
  <div id="contact" class="contact">
    <div class="bg-image">
      <div class="container d-none d-sm-block">
        <div class="row col-12 m-0 justify-content-center">
          <div class="alert fade text-center alert-danger alert-success" role="alert">
          </div>
          <div data-aos="fade-up" class="card">
            <div class="card-body">
              <h4 class="card-title text-center">Contact me</h4>
              <form>
                <div class="form-group">
                  <label for="inputEmail">Email address</label>
                  <input type="email" class="form-control" id="inputEmail" placeholder="Enter email">
                </div>
                <div class="form-group">
                  <label for="inputVacancy">About vacancy</label>
                  <textarea class="form-control" id="inputVacancy" rows="8"
                            placeholder="A short description of your vacancy"></textarea>
                </div>
                <button id="sendEmailSubmit" type="button" class="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="container d-block d-sm-none">
        <div class="row col-12 m-0 justify-content-center">
          <div class="alert fade text-center alert-danger alert-success" role="alert">
          </div>
          <div class="card-m">
            <div class="card-body">
              <h4 class="card-title text-center">Contact me</h4>
              <form>
                <div class="form-group">
                  <label for="inputEmail">Email address</label>
                  <input type="email" class="form-control" id="inputEmail" placeholder="Enter email">
                </div>
                <div class="form-group">
                  <label for="inputVacancy">About vacancy</label>
                  <textarea class="form-control" id="inputVacancy" rows="8"
                            placeholder="A short description of your vacancy"></textarea>
                </div>
                <button id="sendEmailSubmit" type="button" class="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact-item"
}
</script>

<style scoped>
.contact {
  margin-top: 10vh;
  color: #808080;
}
.contact .container {
  padding-top: 10vh;
}
.contact .container form {
  margin-block-end: 0;
}
.contact .container .card {
  width: 40vw;
  border-radius: 0.5em;
  background: rgba(250, 250, 250, 0.9);
}
.contact .container .card textarea {
  resize: none;
}
.contact .container .card-m {
  width: 90vw;
  border-radius: 0.5em;
  background: rgba(240, 240, 240, 0.9);
}
.contact .container .card-m #inputVacancy {
  height: 25vh;
}
.bg-image {
  background: url(../assets/bg_forest.jpg) no-repeat center center fixed;
  background-size: cover;
  position: relative;
  height: 85vh;
}
.card-body {
  padding: 1em 0 1em 0;
}

label {
 margin-top: 0.5em;
}

button {
  margin-top: 1em;
}
</style>