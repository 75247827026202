<template>
  <div class="row">
    <div class="d-none d-sm-block col-sm-3">
      <img class="education-image mx-auto d-block float-end" :src="require('@/assets/' + image )" alt="Logo">
    </div>
    <div class="col-12 col-sm-9">
      <a v-bind:href="href">
        <h4 class="font-weight-bold text-left">
          <img class="education-image-sm mx-auto d-block d-sm-none float-left" :src="require('@/assets/' + image)"
               alt="University logo">
          {{ name }}<br>{{ name_lower }}
        </h4>
      </a>
      <div class="education-lb font-weight-bold">
        {{ education_level }}
      </div>
      <h5 class="font-weight-bold text-left">
        {{ years }}
      </h5>
      <span class="fa fa-university"/>{{ department }}
      <br>
      <span class="fa fa-graduation-cap"/>{{ graduation }}
    </div>
  </div>
</template>

<script>
export default {
  name: "university-item",
  props: {
    name: String,
    name_lower: String,
    education_level: String,
    years: String,
    department: String,
    graduation: String,
    image: String,
    href: String
  }
}
</script>

<style scoped>
.about-me a {
  color: #808080;
}

.about-me a:hover {
  color: #555555;
  text-decoration: none;
}

.education-lb {
  font-weight: bold;
  color: #4551b8;
}

.about-me h5 {
  font-size: 14pt;
  color: #752E9D;
}

.education-image {
  background-size: cover;
  margin-top: 10px;
  height: auto;
  width: 90px;
}

.education-image-sm {
  background-size: cover;
  padding: 10px;
  margin-top: 10px;
  height: auto;
  width: 90px;
}
</style>